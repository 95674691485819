<template>
    <div :class="colorCSSClasses" class="affiliation-card" v-if="showCard">
        <div class="image-wrapper" v-if="showImage && blogImage">
            <Image :url="blogImage" class="image" />
        </div>
        <div class="image-wrapper" v-else-if="showImage && mobileImage">
            <template v-if="logoImage">
                <Image v-bind="mobileImage" class="image background" />
                <Image v-bind="logoImage" class="image logo" />
            </template>
            <Image v-else-if="!logoImage" v-bind="mobileImage" class="image" />
        </div>
        <div class="image-wrapper" v-else-if="showImage && image">
            <template v-if="logoImage">
                <Image v-bind="image" class="image background" />
                <Image v-bind="logoImage" class="image logo" />
            </template>
            <Image v-else-if="!logoImage" v-bind="image" class="image" />
        </div>

        <div class="main">
            <Eyebrow v-if="eyebrow" class="card-eyebrow">
                {{
                    $t(eyebrow, 1, {
                        locale: pageLanguage,
                    })
                }}
                {{ publishDate }}
            </Eyebrow>

            <Anchor :url="langUrl">
                <Typography as="h2" variant="h4-display" class="heading">
                    {{ heading }}
                </Typography>
            </Anchor>

            <RichTextElements :elements="dek" class="dek" bodyStyle="serif-small" />

            <Button :url="langUrl" variant="tertiary" v-if="showSubscribeCta" class="cta">{{
                $t('learn more', 1, {
                    locale: pageLanguage,
                })
            }}</Button>
        </div>
    </div>
</template>

<script setup>
const { currentCenter } = useCenters();
const props = defineProps({
    heading: {
        type: String,
        default: '',
    },
    headingElement: {
        type: String,
        default: 'div',
    },
    eyebrow: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
        default: () => {},
    },
    url: {
        type: String,
        default: '',
    },
    image: {
        type: Object,
        default: null,
    },
    mobileImage: {
        type: Object,
        default: null,
    },
    logoImage: {
        type: Object,
    },
    showSubscribeCta: {
        type: Boolean,
        default: true,
    },
    showImage: {
        type: Boolean,
        default: true,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
    isDCPrimary: {
        type: Boolean,
        default: false,
    },
    ...streamBlock,
});

let showCard = true;

// POST LAUNCH FIXME once we implement the fix for 1980 that hides affiliation cards when not relevant
let isDC = false;
if (currentCenter.value.slug === 'global') {
    isDC = true;
}
if (props.eyebrow === 'program' && isDC && props.pageLanguage === 'en' && props.isDCPrimary) {
    showCard = true;
} else if (
    props.eyebrow === 'program' ||
    props.eyebrow === 'البرنامج' ||
    props.eyebrow === 'Программа' ||
    props.eyebrow === 'कार्यक्रम' ||
    props.eyebrow === '项目'
) {
    showCard = false;
}

let langUrl = props.url;
if (props.pageLanguage.length) {
    langUrl = props.url + '?lang=' + props.pageLanguage;
}

const blogImage = computed(() => {
    if (props.heading === 'Diwan') {
        return '/static/media/images/Diwan-Landing%20header.png';
    } else if (props.heading === 'Carnegie Politika') {
        return '/static/media/images/Politika-Landing%20header.png';
    } else if (props.heading === 'Карнеги Politika') {
        return '/static/media/images/Politika-Landing%20header.png';
    } else if (props.heading === 'China Financial Markets') {
        return '/static/media/images/CFM-Landing%20header.png';
    } else if (props.heading === 'Sada') {
        return '/static/media/images/Sada-Landing%20header.png';
    } else if (props.heading === 'Strategic Europe') {
        return '/static/media/images/Strategic%20Europe-Landing%20header.png';
    } else if (props.heading === 'Emissary') {
        return '/static/media/images/Emmisary-Landing header.png';
    }
});

const colorCSSClasses = computed(() => {
    return [`--color-context-${props.colorContext}`];
});
</script>

<style lang="scss" scoped>
.image-wrapper {
    position: relative;
}
.image {
    height: 17rem;
    object-fit: cover;

    &.background {
        position: relative;
    }

    &.logo {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 25%;
        object-fit: contain;
    }
}

.main {
    padding: 3.6rem 2.4rem;

    .--color-context-white & {
        background-color: palette(bg-gray-dark);
    }
}

.card-eyebrow {
    opacity: 0.5;
    margin-bottom: 0.333333333333333em;
}

.heading {
    color: palette(text-blue);
    opacity: 0.8;
    margin: 0.083333333333333em 0 0.5em;
}

.dek {
    opacity: 0.7;
}

.cta {
    margin-top: 1.5rem;
}
</style>
